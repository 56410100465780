import { getLocaleFromUrl } from './utils';

/**
 * This file ensures that the DOM elements that have a `data-hidden-langs` attribute
 * get actually hidden from the page in the languages indicated in the attribute.
 * 
 * The attribute will accept a comma-separated list of language codes
 * and will look into the language in the URL to match both and
 * decide if the element needs to be hidden.
 * 
 * Example:
 * ```
 * <section class="interview_section" data-hidden-langs="es">
 *      Will not show on Spanish website
 * </section>
 * 
 * <section class="interview_section" data-hidden-langs="de,en">
 *      Will not show on German or English website
 * </section>
 * ```
 */

(() => {
    const elemsToHide = document.querySelectorAll("[data-hidden-langs]");
    const activeLang = getLocaleFromUrl();

    elemsToHide.forEach(elem => {
        const hiddenLangs = elem.dataset.hiddenLangs.split(',');
        if (hiddenLangs.includes(activeLang)) {
            elem.style.display = 'none';
        }
    })
})()