import Swiper, { Pagination } from 'swiper';
// import 'swiper/swiper-bundle.css';

// configure Swiper to use modules
Swiper.use([Pagination]);

(() => {
  const swiper = new Swiper('.swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });
})();
