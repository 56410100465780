const langList = document.querySelector('.language-list');
const langLabel = document.querySelector('.language-label');

if (langList && langLabel) {
// open langList on click
langLabel.addEventListener('click', (e) => {
    e.stopPropagation();
    langList.classList.toggle('show');
});

// close langList when pressing "Esc"
document.addEventListener('keyup', (e) => {
    if (e.key === "Escape") {
        langList.classList.remove('show');
    }
});

// close langList when clicking outside the menu
window.addEventListener('click', (e) => {
    if (e.target != langList || e.target != langLabel) {
        langList.classList.remove('show');
    }
});
}
