import { getLocaleFromUrl } from './utils';

function getBrowserLocale() {
    const browserLocales =
        navigator.languages === undefined
        ? [navigator.language]
        : navigator.languages;

    if (!browserLocales) {
        return undefined;
    }

    return browserLocales[0];
}

(() => {
    const localeFromUrl = getLocaleFromUrl();
    let locale = 'de-DE';
    if (localeFromUrl === 'en') {
        locale = getBrowserLocale();
    }
    const elems = document.querySelectorAll('[data-format-date]');
    elems.forEach(elem => {
        const date = new Date(elem.dataset.formatDate);
        elem.textContent = date.toLocaleDateString(locale, {year: 'numeric', month: 'short', day: 'numeric'});
    });
})();