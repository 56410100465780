import { calculatePagination } from './pagination';

(() => {
  // Tab switcher
  const deItems = document.querySelectorAll('div[data-language="de"]');
  const enItems = document.querySelectorAll('div[data-language="en"]');

  const deButton = document.querySelector('#tab-button_de');
  const enButton = document.querySelector('#tab-button_en');

  if (deButton) {
    deButton.addEventListener('click', (ev) => {
      deButton.classList.add('active');
      enButton.classList.remove('active');
      deItems.forEach((item) => item.classList.remove('invisible'));
      enItems.forEach((item) => item.classList.add('invisible'));
      calculatePagination();
    });
  }

  if (enButton) {
    enButton.addEventListener('click', (ev) => {
      enButton.classList.add('active');
      deButton.classList.remove('active');
      enItems.forEach((item) => item.classList.remove('invisible'));
      deItems.forEach((item) => item.classList.add('invisible'));
      calculatePagination();
    });
  }
  // End tab switcher

  // Secondary navbar for mobile
  const menuOpener = document.querySelector('#press-navbar_opener');
  const navbarItemWrapper = document.querySelector(
    '.press-navbar_item-wrapper'
  );

  if (menuOpener && navbarItemWrapper) {
    menuOpener.addEventListener('click', () => {
      navbarItemWrapper.classList.toggle('open');
    });
  }
  // End secondary navbar for mobile

})();
