'use strict';
const mobileMenuBtn = document.querySelector('.mobile-header_btn');
const mobileMenu = document.querySelector('.mobile-menu');
if (mobileMenuBtn) {
  mobileMenuBtn.addEventListener('click', toggleMobileMenu);
}
let mobileMenuToggle = true;

function toggleMobileMenu() {
  if (mobileMenuToggle) {
    mobileMenuBtn.classList.add('mobile-header_btn--active');
    mobileMenu.classList.add('mobile-menu--active');
    mobileMenuToggle = false;
  } else {
    mobileMenuBtn.classList.remove('mobile-header_btn--active');
    mobileMenu.classList.remove('mobile-menu--active');
    mobileMenuToggle = true;
  }
}
