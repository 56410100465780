import './css/style.css';

// import './libs/swiper-bundle.min.js';
import './js/mobile-menu.js';
import './js/slider.js';
// import './js/form-validate.js';
import './js/tooltip.js';
import './js/form-submit.js';
import './js/press.js';
import './js/date-formatting.js';
import './js/hidden-langs.js';
import './js/language-selector.js';
