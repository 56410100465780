'use strict';

async function digestMessage(message) {
  const msgUint8 = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); 
  const hashArray = Array.from(new Uint8Array(hashBuffer)); 
  const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, '0'))
      .join(''); 
  return hashHex;
}

(async () => {
  const formEl = document.querySelector('.nect-form');
  if (formEl === null) {
    return;
  }

  const formSubmitBtn = document.querySelector('#form-submit-button');

  // Feature check and top offset used by the validation function
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
  const navBarOffset = 220;

  // In the GDPR form, for the birthdate, we set the max date to be at least six years ago
  const birthdateInput = document.querySelector('input#birthdate');
  if (birthdateInput) {
    const date = new Date();
    const maxDate = new Date(date.setFullYear(date.getFullYear() - 6));

    birthdateInput.max = maxDate.toISOString().split('T')[0];
  }

  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  const getRandomOperator = () => {
    const operators = [
      {
        simbol: '+',
        reducer: (prev, num) => prev + num,
      },
      {
        simbol: '-',
        reducer: (prev, num) => prev - num,
      }
    ];

    return operators[Math.floor(Math.random() * operators.length)];
  };

  // GDPR form: Set a challenge question and Nect case ID second try flag
  if (formEl.id === 'gdpr-form') {
    const challengeLabel = formEl.querySelector('#challenge_label');
    const challengeInput = formEl.querySelector('#challenge');

    const challengeOperator = getRandomOperator();
    const challengeNumbers = [getRandomNumber(5, 9), getRandomNumber(1, 5)];

    const challenge = `${challengeNumbers[0]} ${challengeOperator.simbol} ${challengeNumbers[1]}`;
    window.challengeSolution = challengeNumbers.reduce(challengeOperator.reducer);

    challengeLabel.innerText = challenge;
    challengeInput.placeholder = challenge;

    window.askForNectCaseId = true;
    window.checkBirthdate = true;
  } else {
    formEl.addEventListener('click', function () {
      window.location.href = document.getElementById("contact-button").getAttribute('href');
    })
  }

  const validationMessage = (
    field,
    type = 'error',
    msg = 'There is something wrong with your message. Please double check your input and try again.'
  ) => {
    let msgEl;
    if (typeof field === 'string') {
      msgEl = document.querySelector(`.js-msg-${field}`);
    } else {
      field.focus();
      msgEl = formEl.querySelector(`.js-msg-${field.name}`);
    }
    msgEl.classList.add('visible', type);
    msgEl.innerText = msg;

    if (supportsNativeSmoothScroll) {
      window.scrollBy({
        top: msgEl.getBoundingClientRect().top - navBarOffset,
        behavior: 'smooth'
      });
    } else {
      window.scrollBy(0, msgEl.getBoundingClientRect().top - navBarOffset);
    }

    formSubmitBtn.disabled = false;
  };


  let sessionID = '';
  let nonce = 0;
  let foundNonce = false;
  if (formEl.id === 'gdpr-form') {
    
    const doWork = async () => {
      const req = await fetch('/api/generate-challenge/', {
        method: 'GET'
      });

      const response = await req.json();

      if(response && response.sessionID) {
        sessionID = response.sessionID;
        const { challengeString, difficult } = response;
        nonce = 0;
        const startDate = new Date();
        let hash = await digestMessage(challengeString + nonce);
        while (
            hash.substring(0, difficult) !==
            '0'.repeat(difficult)
        ) {
            hash = await digestMessage(challengeString + nonce);
            nonce += 1;
        }

        nonce -= 1;
        foundNonce = true;
        const endDate = new Date();
        // console.log('Found the answer');
        // console.log(`The answer is ${nonce} with a final hash of ${hash}`);
        // console.log(
        //     `${
        //         endDate.getSeconds() - startDate.getSeconds()
        //     } seconds to complete`,
        // );
      }
    }
    doWork();
  }
  formEl.addEventListener('submit', async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    formSubmitBtn.disabled = true;

    formEl.querySelectorAll('.js-validation-msg').forEach((field) => {
      field.classList.remove('visible', 'error', 'warning', 'success');
    });

    // Validation for some more "advanced" rules
    // not easily implementable with native HTML validation only

    if (formEl.id === 'gdpr-form') {
      const idNumberField = formEl.querySelector('#id_number');
      const caseIdField = formEl.querySelector('#case_id');
      const challengeField = formEl.querySelector('#challenge');

      const reasonInfoCheckbox = formEl.querySelector('#reason_information');
      
      if(birthdateInput.value.indexOf('-') !== -1) {
        const birthdateYear = parseInt(birthdateInput.value.split('-')[0], 10);
        if(birthdateYear >= new Date().getFullYear() - 18 && window.checkBirthdate) {
          window.checkBirthdate = false;
          validationMessage(
            birthdateInput,
            'warning',
            'Optional: Please check if the birthdate matches with your document.'
          );
          return;
        }
      }

      const reasonRectifCheckbox = formEl.querySelector(
        '#reason_rectification'
      );
      const reasonRectifExplanation = formEl.querySelector(
        '#reason_rectification_explanation'
      );
      const reasonErasureCheckbox = formEl.querySelector('#reason_erasure');

      // 1) the GDPR form needs either a document number *or* a case ID
      if (idNumberField.value.length === 0 && caseIdField.value.length === 0) {
        validationMessage(
          idNumberField,
          'error',
          'Please introduce either your ID Number or your Nect case ID. You can see some more detailed instructions when putting your cursor on top of the icon next to the field labels.'
        );
        return;
      } else if (
        // 2) the GDPR form needs at least one "reason" checkbox activated
        reasonInfoCheckbox.checked === false &&
        reasonRectifCheckbox.checked === false &&
        reasonErasureCheckbox.checked === false
      ) {
        validationMessage(reasonInfoCheckbox, 'error', 'Please choose at least one reason to contact us.');
        return;
      } else if (
        reasonRectifCheckbox.checked === true &&
        reasonRectifExplanation.value.length === 0
      ) {
        validationMessage(
          reasonRectifExplanation,
          'error',
          'Please add as much detail as possible about the rectification that needs to be done.'
        );
        return;
      } else if (window.challengeSolution && parseInt(challengeField.value) !== window.challengeSolution) {
        // 3) Challenge result validation
        validationMessage(challengeField, 'error', 'Please check the security code.');
        return;
      } else if (!foundNonce) {
        validationMessage(challengeField, 'error', 'Checking your browser please try again.');
        return;
      } else if (
        // if only document number is there, we kindly ask for nect case id (only once)
        idNumberField.value.length !== 0 &&
        caseIdField.value.length === 0 &&
        window.askForNectCaseId
      ) {
        window.askForNectCaseId = false;
        validationMessage(
          caseIdField,
          'warning',
          'Optional: Please consider adding your Nect case ID, it would highly speed up the process. Or submit again to skip.'
        );
        return;
      }
    }

    const typeOfForm = formEl.id;
    const submissionUrl =
      typeOfForm === 'gdpr-form'
        ? '/api/gdpr-form/'
        : '/code/submit_contactform.php';

    const formData = new FormData(ev.target);
    var bodyJSON = {sessionID, nonce};
    formData.forEach(function(value, key){
        bodyJSON[key] = value;
    });
    const req = await fetch(submissionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyJSON),
    });

    const response = await req.text();
    const status = req.status;

    if (status > 499) {
      validationMessage('general_feedback', 'warning', 'Please excuse us, but we are currently experiencing technical difficulties. Please try again later.');
    } else if (status > 399) {
      validationMessage('general_feedback', 'error');
    } else if (status === 200) {
      validationMessage('general_feedback', 'success', 'We successfully received your request. Thank you!');
      formEl.reset();
      formSubmitBtn.textContent = 'Thank you!';
      formSubmitBtn.disabled = true;
    } else {
      validationMessage('general_feedback', 'warning', 'Please excuse us, but we are currently experiencing technical difficulties. Please try again later.');
    }
  });
})();
