'use strict';

(() => {
  const tooltipIcons = document.querySelectorAll('.has-tooltip');

  for (const icon of tooltipIcons) {
    icon.addEventListener('mouseover', ({ target }) => {
      const tooltip = target.parentElement.querySelector('.tooltip');
      if (!!tooltip) {
        tooltip.classList.add('visible');
      }
    });
    icon.addEventListener('mouseleave', ({ target }) => {
      const tooltip = target.parentElement.querySelector('.tooltip');
      if (!!tooltip) {
        tooltip.classList.remove('visible');
      }
    });
  }
})();
