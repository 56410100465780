let paginationGlobalObj;
let paginationCurrentPage = 1;

const paginate = (direction) => {
  if (direction === 'newer' && paginationCurrentPage > 1) {
    paginationCurrentPage--;
  }

  if (direction === 'older' && paginationCurrentPage < paginationGlobalObj.lastPage) {
    paginationCurrentPage++;
  }

  // Reset buttons
  paginationNewerItemsAction.classList.remove('disabled');
  paginationOlderItemsAction.classList.remove('disabled');

  // Change content
  if (paginationCurrentPage <= 1) {
    paginationNewerItemsAction.classList.add('disabled');
  }
  if (paginationGlobalObj.lastPage > 1 && paginationCurrentPage >= paginationGlobalObj.lastPage) {
    paginationOlderItemsAction.classList.add('disabled');
  }
  if (paginationGlobalObj.lastPage === 0 || paginationGlobalObj.lastPage === 1) {
    paginationNewerItemsAction.classList.add('disabled');
    paginationOlderItemsAction.classList.add('disabled');
  }

  paginationGlobalObj.itemsNodes.forEach((item, index) => {
    // We hide items outside of the current page range
    if (
      index < ((paginationCurrentPage * paginationGlobalObj.itemsPerPage) - paginationGlobalObj.itemsPerPage) ||
      index >= (paginationCurrentPage * paginationGlobalObj.itemsPerPage)
    ) {
      item.style.display = 'none';
    } else {
      // Or, we reset display prop to show items inside the page range
      item.style.display = null;
    }
  });
};

export const calculatePagination = () => {
  paginationCurrentPage = 1;
  const paginationItemsPerPage = 10;
  const paginationItemsNodes = document.querySelectorAll('[data-pagination-item]:not(.invisible)');
  const paginationItemsInPage = paginationItemsNodes.length;

  paginationGlobalObj = {
    itemsPerPage: paginationItemsPerPage,
    itemsNodes: paginationItemsNodes,
    lastPage: Math.ceil(paginationItemsInPage/paginationItemsPerPage),
  };
  paginate();
}

const paginationNewerItemsAction = document.querySelector('#pagination-newer');
const paginationOlderItemsAction = document.querySelector('#pagination-older');

if (paginationNewerItemsAction && paginationOlderItemsAction) {
  paginationNewerItemsAction.addEventListener('click', (ev) => {
    paginate('newer');
  });
  paginationOlderItemsAction.addEventListener('click', (ev) => {
    paginate('older');
  });

  calculatePagination();
}
